import React,{useState,useEffect,useRef,useCallback} from "react";
import './css/fifthDisplay.css';
import "@fontsource/red-hat-display";
import bgContentWriting from './resources/content-writing.svg';
import contentWritingBottom from './resources/content-writing-bottom.svg';
import { Link } from "react-router-dom";

function FifthDisplay(){

    return (
       
        <div className="fifthDisplay">
        {/* <div className="fifthDisplaySub">
            <img src={bgContentWriting} className="bgContentWriting"/>
            <div className="bgContentWritingData">
                <div className="bgContentWritingDataText">
                    <h2>Content Writing</h2>
                    <p>Confederacie will provide content writing service. If possible please use a good Ilustration in this section.</p>
                </div>
            </div>
        </div> */}
        {/* <div className="opacityBox"></div> */}

        <br/>
        <h1 className="dev-phase-heading">Development Phase</h1>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod<br/> tempor incididunt ut labore et.</p> */}
        {/* <div className="leftRightCircle">
            <div>&lt;</div>
            <div>&gt;</div>
            </div> */}
            <div className="row">
                <Link to="software-development" className="box box-md-2-5 content-writing-row">
                <div className="dev-phase-row">
                    <div className="box-md-3-5" style={{'margin': 'auto'}}>
                <svg  width="112" height="56" viewBox="0 0 112 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.44 49.4667L15.68 28L41.44 6.53333L33.6 0L0 28L33.6 56L41.44 49.4667ZM70.56 49.4667L96.32 28L70.56 6.53333L78.4 0L112 28L78.4 56L70.56 49.4667Z" fill="#6A99AB"/>
</svg></div>

                    <div className="box-md-2-5" style={{'margin': 'auto'}}>
                    <h3 style={{color:'#000'}}>Software Architecture and Development</h3>
                    <p style={{color:'#000'}}>We create architectural blueprints that will ensure that your digital product meets all essential operational/technical requirements. </p>
                    </div>
                    </div>
                </Link>
                <Link to="software-development" className="box box-md-3-5">
                <div className="dev-phase-row">
                <div>
                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M55.1111 55.1111H6.88889V6.88889H31V0H6.88889C3.06556 0 0 3.1 0 6.88889V55.1111C0 58.9 3.06556 62 6.88889 62H55.1111C58.9 62 62 58.9 62 55.1111V31H55.1111V55.1111ZM37.8889 0V6.88889H50.2544L16.3956 40.7478L21.2522 45.6044L55.1111 11.7456V24.1111H62V0H37.8889Z" fill="#5C86A7"/>
</svg>


                    <h3 style={{color:'#000'}}>Resilent Infrastructure</h3>
                    <p style={{color:'#000'}}>We'll help you create a customized digital infrastructure by transferring your organization's digital infrastructure into scalable environments. </p>
                    </div>
                </div>
                </Link>
                </div>
                <div className="row">
                <Link to="software-development" className="box box-md-2">
                <div className="dev-phase-row">
                <div className="box-md-3-5" style={{'margin': 'auto'}}>

                <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_944_569)">
<path d="M41.8895 6.1665C43.5249 6.1665 45.0934 6.81619 46.25 7.97269L59.8604 21.5832C61.017 22.7396 61.6666 24.3082 61.6666 25.9437V61.6665C61.6666 65.0724 58.9058 67.8332 55.5 67.8332H18.5C15.0942 67.8332 12.3333 65.0724 12.3333 61.6665V12.3332C12.3333 8.92741 15.0942 6.1665 18.5 6.1665H41.8895ZM37 12.3332H18.5V61.6665H55.5V30.8332H41.625C39.0707 30.8332 37 28.7625 37 26.2082V12.3332ZM41.9053 39.0259C43.1093 37.8219 45.0617 37.8219 46.2657 39.0259C47.4697 40.2299 47.4697 42.1823 46.2657 43.3863L36.6725 52.9795C35.3482 54.3041 33.2007 54.3041 31.8761 52.9795L27.7336 48.8371C26.5295 47.633 26.5295 45.6807 27.7336 44.4766C28.9378 43.2726 30.89 43.2726 32.0941 44.4766L34.2743 46.6568L41.9053 39.0259ZM43.1666 13.6103V24.6665H54.2229L43.1666 13.6103Z" fill="#6A99AB"/>
</g>
<defs>
<clipPath id="clip0_944_569">
<rect width="74" height="74" fill="white"/>
</clipPath>
</defs>
</svg>
</div>

                    <div className="box-md-2-5">
                    <h3 style={{color:'#000'}}>Testing Phase</h3>
                    <p style={{color:'#000'}}> We collaborate with Quality Assurance specialists to provide layout and user testing to identify and resolve errors/inconsistencies with digital products. </p>
                    </div>
                    </div>
                </Link>
                <Link to="software-development" className="box box-md-2">
                <div className="dev-phase-row">
                <div className="box-md-3-5" style={{'margin': 'auto'}}>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M59.2142 49.0412L34.4331 24.1098C36.884 17.8085 35.5224 10.4113 30.3483 5.20588C24.9019 -0.273536 16.7323 -1.36942 10.1967 1.64426L21.9064 13.425L13.7368 21.6441L1.75476 9.86338C-1.51308 16.4387 -0.151484 24.6578 5.29491 30.1372C10.469 35.3426 17.8216 36.7125 24.085 34.2468L48.8661 59.1781C49.9554 60.274 51.5893 60.274 52.6786 59.1781L58.9419 52.8768C60.3035 51.7809 60.3035 49.8631 59.2142 49.0412Z" fill="#6A99AB"/>
</svg>
</div>

<div className="box-md-2-5">
                    
                    <h3 style={{color:'#000'}}>Launch and Maintainance</h3>
                    <p style={{color:'#000'}}> Launch and maintenance of digital products are ensured through our automated code review/testing tools and tracking tools. </p>
                    </div>
                    </div>
                </Link>
                

            </div>
        {/* <div className="contentWritingBottomImg">
        <img src={contentWritingBottom}/>
        </div> */}
    </div>
        

    );
}
export default FifthDisplay;

