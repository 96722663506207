import React from "react";
import './SoftwareDevelopment.css';
import "@fontsource/red-hat-display";
import './../min.css';
import product_life from './resources/product_life.svg'
import testMaintain from './resources/test-maintain.svg'
function    TestCode(){
    return(
            <div style={{"width":"100%","text-align":"center","margin":"auto","padding-top":"100px", "background": "linear-gradient(180deg, rgba(79, 80, 80, 0) 0%, rgba(94, 95, 95, 0.2) 100%)"}}>

                        <h1>Test and Maintain Code</h1>

        <div className="body-works">

        <div className="div-40" style={{"width":"100%"}}>
                <img src={testMaintain} className="display-overlay" style={{"top":"0px"}}/>
            </div>
            <div className="div-60 div-num" style={{"color":"#5C86A7","vertical-align":"middle", margin: 'auto'}}>
            <div style={{margin:'auto','text-align':'center'}}><p className="heading-text-h2" style={{color:'#000'}}>Free code quality vulnerability checker.</p></div>
            <img src={testMaintain} className="background-bg"/>

            <div tyle={{'text-align':'center'}}><p className="para-text-h3" style={{color:'#000', 'float':'left'}}>We use the latest static application security testing (SAST) tools to transform source code into data structures. Then, we use advanced scanners with sophisticated rulesets to search for malicious patterns in our clients’ source code. </p>
            <p className="para-text-h3" style={{color:'#000', 'float':'left'}}>Use our free code quality & vulnerability tester today. Scan your code security issues. Fix your team’s integrated development environment (IDE) with the expert advice from our free code checker tool. 
</p></div>
            </div>
           

        </div></div>
    );


}
export default TestCode;