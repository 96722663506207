import React from "react";
import { useState } from "react"
import './Navigation.css';
import "@fontsource/red-hat-display";
import logo from './logo.png';
import hamburger from './hamburger.svg';
import { Link } from "react-router-dom";

function Navigation(){
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    return(
        <nav className="navigation">
        <a href="/" className="brand-name">
        <img style={{'height':'50px'}}src={logo}/>
        </a>
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded)
          }}
        >
         <img src={hamburger} style={{width:'100%'}}/>
        </button>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul>
            <li>
            <Link to="/" onClick={() => {
              if(isNavExpanded){
                setIsNavExpanded(!isNavExpanded)
              }
          }}>Home</Link>
            </li>
            <li>
            <Link to="/visualization" onClick={() => {
              if(isNavExpanded){
                setIsNavExpanded(!isNavExpanded)
              }
          }}>Visualization</Link>
            </li>
            <li>
              <Link to="/virtualization" onClick={() => {
              if(isNavExpanded){
                setIsNavExpanded(!isNavExpanded)
              }
          }}>Virtualization</Link>
            </li>
            {/* <li>
              <Link to="/governance" onClick={() => {
              if(isNavExpanded){
                setIsNavExpanded(!isNavExpanded)
              }
          }}>Governance</Link>
            </li>
            <li>
              <Link to="/prototyping" onClick={() => {
              if(isNavExpanded){
                setIsNavExpanded(!isNavExpanded)
              }
          }}>Prototyping</Link>
            </li> */}
            <li>
              <Link to="/software-development" onClick={() => {
              if(isNavExpanded){
                setIsNavExpanded(!isNavExpanded)
              }
          }}>Devops</Link>
            </li>
            <li>
              <Link to="/contact" onClick={() => {
              if(isNavExpanded){
                setIsNavExpanded(!isNavExpanded)
              }
          }}>Augmented Team</Link>
            </li>
            
          </ul>
        </div>
      </nav>
    );
}
export default Navigation;