import React from "react";
import './AugmentedTeam.css';
import "@fontsource/red-hat-display";
import './../min.css';
import augmented_bg from './resources/augmented_bg.svg'
function Display(){
    return(
        <div className="body-augmented">

            <h1 className="heading-text-h1"  style={{'padding-top':'10%'}}>Introducing to our<br/>Augmented Team</h1>
            <br/><h3 style={{padding:'2%'}}>Let us streamline your organization’s adoption of DevOps practices.<br/> We’ll provide a complete turnkey Devops solution your team<br/> can run with well into the future.</h3>

        </div>
    );


}
export default Display;