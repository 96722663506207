import React,{useState} from "react";
import './AugmentedTeam.css';
import "@fontsource/red-hat-display";
import './../min.css';
import "@fontsource/inter";
import { render } from "react-dom";

import augmented_bg from './resources/augmented_bg.svg'
import developers from './resources/developers.svg';
import ReactPhoneInput from "react-phone-input-2";

function Contact(){
    const [ph,setPh] = useState("+1");

    return(
        <div className="body-contact">
           <div className="div-pane" style={{"margin":"auto"}}>
           <h1>Contact Us</h1>
           <p>Looking for an augmented team?<br/>
            You found. </p>

           {/* <svg style={{"padding":"20px"}} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_650_585)">
<path d="M25.3333 0H6.66667C2.98533 0 0 2.98533 0 6.66667V25.3333C0 29.0147 2.98533 32 6.66667 32H25.3333C29.016 32 32 29.0147 32 25.3333V6.66667C32 2.98533 29.016 0 25.3333 0ZM10.6667 25.3333H6.66667V10.6667H10.6667V25.3333ZM8.66667 8.976C7.37867 8.976 6.33333 7.92267 6.33333 6.624C6.33333 5.32533 7.37867 4.272 8.66667 4.272C9.95467 4.272 11 5.32533 11 6.624C11 7.92267 9.956 8.976 8.66667 8.976ZM26.6667 25.3333H22.6667V17.8613C22.6667 13.3707 17.3333 13.7107 17.3333 17.8613V25.3333H13.3333V10.6667H17.3333V13.02C19.1947 9.572 26.6667 9.31733 26.6667 16.3213V25.3333Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_650_585">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>
<svg style={{"padding":"20px"}} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_650_578)">
<path d="M6.12501 6.64489C6.61721 6.58752 7.10062 6.80783 7.38022 7.21693C9.34739 10.0953 11.7102 11.2928 14.3319 11.5652C14.4605 10.4436 14.7632 9.3354 15.3321 8.35972C16.1667 6.92819 17.524 5.87427 19.4629 5.48483C22.1421 4.94669 24.1821 5.91696 25.3659 7.10524L27.7549 6.65863C28.2653 6.56324 28.7845 6.77271 29.0859 7.19548C29.3871 7.61825 29.4156 8.17745 29.1588 8.62867L26.8661 12.6581C27.0752 18.4733 25.4592 22.5316 22.0137 25.3276C20.186 26.8106 17.5707 27.6518 14.6999 27.9116C11.8066 28.1733 8.53619 27.8568 5.2851 26.9074C4.71473 26.7409 4.32339 26.217 4.3255 25.6229C4.32759 25.0286 4.72261 24.5076 5.29414 24.345C6.9291 23.8801 8.1735 23.4781 9.29837 22.7761C7.70042 21.9277 6.52301 20.8186 5.68969 19.5542C4.53146 17.7968 4.10198 15.8376 4.01671 14.0705C3.93146 12.3036 4.18679 10.6671 4.45571 9.48604C4.60879 8.81369 4.78759 8.13821 5.03405 7.49295C5.21305 7.02788 5.63282 6.70228 6.12501 6.64489Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_650_578">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>

<svg style={{"padding":"20px"}}     width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 10.6667H0V16H4V32H10.6667V16H15.5227L16 10.6667H10.6667V8.444C10.6667 7.17067 10.9227 6.66667 12.1533 6.66667H16V0H10.9227C6.128 0 4 2.11067 4 6.15333V10.6667Z" fill="white"/>
</svg> */}


<br/>
<br/>

<br/>
<br/>
<br/>
<br/>



           <div className="heading-text-h2" style={{"margin-bottom":"20px","color":"#000"}}>
           What’s the nextstep?
                    <p className="para-text-h3">You are one step closer to build your perfect product. </p>
                    
                </div>
                <ol style={{"margin-bottom":"20px","color":"#000"}}>
                        <li className="bg-black">Discuss With Us</li>
                        <li className="bg-black">We will prepare a plan that works</li>
                        <li className="bg-black">Strategize and work</li>

                    </ol>
            </div>


            <div className="div-pane">
            <div style={{"width":"90%","padding":"5%","margin":"0 auto","background":"#F8FAFC","height":"auto","box-shadow": "0px 4px 30px 6px rgba(92, 134, 167, 0.25)","border-radius": "10px"}}>
            <form action="https://api.web3forms.com/submit" method="POST">
                <div style={{"display":"flex","flex-direction":"row","width":"100%"}}>
    <input type="text" className="input-box" name="company" style={{"width":"45%","margin":"2.5%"}} placeholder="Company" required/>
    <input type="text" className="input-box" name="name" style={{"width":"45%","margin":"2.5%"}} placeholder="Full Name" required/>
    </div>
    <div style={{"display":"flex","flex-direction":"row","width":"100%"}}>
    <input type="email" className="input-box" name="email" style={{"width":"95%","margin":"2.5%"}} placeholder="Email" required/>
    </div>
    <div style={{"display":"flex","flex-direction":"row","width":"100%"}}>
    <ReactPhoneInput
            inputExtraProps={{
                name: "phone",
                required: true,
                autoFocus: true
            }}
            defaultCountry={"sg"}
            editable={false}
            style={{"padding-left": "60px","padding-right": "10px"}}
            inputStyle={{"color":"#000","width":"100px","height":"100%",height: "50px","padding-left": "50px","padding-right": "10px"}}
            containerStyle={{"color":"#000","width":"80px","height":"100%",height: "50px","padding-left": "10px","padding-right": "10px"}}
          value={ph}
          onChange={(value)=>setPh(value)}
        />
    {/* <input type="text" className="input-box" name="ph_code" style={{"width":"20%","margin":"2.5%"}} placeholder="Country Code" /> */}
    <input type="text" className="input-box" name="ph_no" style={{"width":"75%","margin-left":"2.5%"}} placeholder="Phone Number" required/>
       </div>
       <div style={{"display":"flex","flex-direction":"row","width":"100%"}}>
    <input type="number" className="input-box" name="no_of_employees" style={{"width":"95%","margin":"2.5%"}} min="1"  placeholder="Total Employees In You Company" required/>
    </div>
    <div style={{"display":"flex","flex-direction":"row","width":"100%"}}>
    <textarea type="text" className="input-box" name="Message" style={{"width":"95%","margin":"2.5%"}} rows="10" cols="50" placeholder="Message" required/>
    </div>
    <input type="hidden" name="redirect" value="https://mogra.ai/"/>

    <input type="hidden" name="access_key" value="ba9072d1-909f-4f5d-a5f0-0e84bb00232c"></input>
    <input type="submit" value="Submit" className="source-button" style={{"height":"100%","margin":"auto"}}/>

</form>

            </div>
           </div>
           <br/>
           <br/>
        </div>
    );


}
export default Contact;