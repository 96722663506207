import React from "react";
import { useState } from "react"
import './bottomNavigation.css';
import "@fontsource/red-hat-display";
// import logo from './bottom-logo.svg';
import logo from './logo.png';

import hamburger from './hamburger.svg';
import { Link } from "react-router-dom";
import './min.css'
function BottomNavigation(){

    return(
        <div className="bottomNav">
            <div className="row">
                <div className="box box-nav box-md-4"><div style={{'display':'flex','flex-direction':'row',color:'#fff'}}><img src={logo} style={{width:'100%',color:'#fff'}} /></div><p style={{color:'#fff'}}>Our vision is to help every digital dream come alive through a better and sustain security</p></div>
                <div className="box box-nav box-md-4">
                    <h3  className="heading-text-h3" style={{'text-align':'left','color':'#fff'}}>Services</h3>
                    <Link className="box-nav-link" to="/"><p>Home</p></Link>

                    <Link className="box-nav-link" to="visualization"><p>Visualization</p></Link>
                    <Link className="box-nav-link" to="virtualization"><p>Virtualization</p></Link>
                    <Link className="box-nav-link" to="software-development"><p>Software Development</p></Link>

                    <Link className="box-nav-link" to="contact"><p>Augmented Team</p></Link>

                </div>
                {/* <div className="box box-nav box-md-4">
                    <h3  className="heading-text-h3" style={{'text-align':'left'}}>Services</h3>
                    <Link className="box-nav-link" to="prototyping"><p>Prototyping</p></Link>

                    <Link className="box-nav-link" to="contact"><p>Contact</p></Link>

                </div> */}
                {/* <div className="box box-nav box-md-4">
                    <h3  className="heading-text-h3" style={{'text-align':'left'}}>Products</h3>
                    <Link className="box-nav-link" to="test.html"><p>Product 1</p></Link>
                    <Link className="box-nav-link" to="test.html"><p>Product 2</p></Link>
                    <Link className="box-nav-link" to="test.html"><p>Product 3</p></Link>
                    <Link className="box-nav-link" to="test.html"><p>Product 4</p></Link>

                </div> */}
            </div>
            <div style={{'width':'100%'}}>
                <div stye={{'width':'50%','position':'relative','left':'0px',}}><p style={{'color':'#fff'}}>© 2023 Mogra. All Rights Reserved.</p></div>

            </div>
        </div>
    );
}
/* Confederacie. */




export default BottomNavigation;