import React,{useState,useEffect,useRef,useCallback} from "react";
import './AugmentedTeam.css';
import "@fontsource/red-hat-display";
import './../min.css';
import augmented_bg from './resources/augmented_bg.svg'
import post_job from './resources/post-job.svg'
import freelancer from './resources/freelancer.svg'
import need_something from './resources/need-something.svg'
import whats_great from './resources/whats-great.svg'
import selected from './resources/selected.svg';
import not_selected from './resources/not-selected.svg';
import browsePictures from './resources/browse-pictures.svg'
import qualityWork from './resources/quality-work.svg'
import trackProgress from './resources/track-progress.svg'
import fastBids from './resources/fast-bids.svg'
function WhatIsGreat(){
    const [slideIndex, setSlideIndex] = useState(0);
    const [slideCount,setSlideCount]=useState(0);
    const [prevScreen,setPrevScreen]=useState(2);
    const [n,setN]=useState(1);
    const stateRef = useRef();  
    const slider=document.getElementById('slider');
    stateRef.current=slideIndex;
    const showDivs=(n)=>{
        if (slideIndex+n >= slideCount) {
            setSlideIndex(0);
           
        }    
        else if (slideIndex+n < 0) {
            setSlideIndex(slideCount-1);
        }
        else{
            setSlideIndex(slideIndex+n);
        }
        if(slider.style.flexDirection=="row"){
            slider.style.flexDirection="row-reverse"

        }
        else{
            slider.style.flexDirection="row"

        }
        setN(-n)
        
       
         
      }

      useEffect(()=>{
        var i;   
        var x = document.getElementsByClassName("great-slides");
        for (i = 0; i < x.length; i++) {
            x[i].style.display = "none";  
        }
        x[slideIndex].className="great-slides fade-in";
        x[slideIndex].style.display = "inline-flex";
        setSlideCount(document.getElementsByClassName("great-slides").length);

      },[slideIndex]);
    return(
        <div className="body-what-is-great">
                <br/>
                <div id="slider" style={{"width":"50px","text-align":"left","padding-left":"15%","display":"flex","flex-direction":"row"}}>
                    <img src={selected} style={{"padding-left":"5px","padding-right":"5px","width":"60%"}}/>
                    <img src={not_selected} onClick={()=>showDivs(n)} style={{"padding-left":"5px","padding-right":"5px","cursor":"pointer","width":"40%"}}/>
                </div>

            <div className="great-slides-div-pane">
                <div id="great-slides">
                    {/* <div className="great-slides">
                    <div className="div-pane">
                    <h1>Need something done?</h1>

                        <div className="WhatIsGreat-box-inline">
                        
                        <div>

                        <div className="WhatIsGreat-box">
                            <img src={post_job}/>
                            <br/>
                            <h3>Post a Job</h3>
                            <br/>
                            <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids come within minutes </p>
                            <br/>
                        </div>
                        <div className="WhatIsGreat-box">
                            <div className="WhatIsGreat-box-inline"><img src={freelancer}/><h3>Choose<br/>FreeLancers</h3></div>
                            <br/>
                           <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids come within minutes </p>
                            <br/>
                        </div>
                        </div>
                        <div>
                        <div className="WhatIsGreat-box">
                            <div className="WhatIsGreat-box-inline"><img src={freelancer}/><h3>Choose<br/>FreeLancers</h3></div>
                            <br/>
                           <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids come within minutes </p>
                            <br/>
                        </div>
                        <div className="WhatIsGreat-box">
                            <img src={post_job}/>
                            <br/>
                            <h3>Post a Job</h3>
                            <br/>
                            <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids come within minutes </p>
                            <br/>
                        </div>
                        
                        </div>
                        </div>
                    </div>
                    <div className="div-pane display-block-overlay">
                    <img src={need_something} className="display-overlay" style={{"width":"100%"}}/>
                    </div>
                    </div> */}
                    <div className="great-slides">
                    <div className="div-pane">
                    <h1>What’s great about it?</h1>

                        <div className="WhatIsGreat-box-inline">
                        
                        <div>

                        <div className="WhatIsGreat-box">
                            <img src={browsePictures}/>
                            <br/>
                            <h3>Browse Portfolios</h3>
                            <br/>
                            <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids come within minutes </p>
                            <br/>
                        </div>
                        <div className="WhatIsGreat-box">
                            <div className="WhatIsGreat-box-inline"><img src={qualityWork}/><h3>Quality work</h3></div>
                            <br/>
                           <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids come within minutes </p>
                            <br/>
                        </div>
                        </div>
                        <div>
                        <div className="WhatIsGreat-box">
                            <div className="WhatIsGreat-box-inline"><img src={fastBids}/><h3>Choose<br/>FreeLancers</h3></div>
                            <br/>
                           <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids come within minutes </p>
                            <br/>
                        </div>
                        <div className="WhatIsGreat-box">
                            <img src={trackProgress} />
                            <br/>
                            <h3>Post a Job</h3>
                            <br/>
                            <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids come within minutes </p>
                            <br/>
                        </div>
                        
                        </div>
                        </div>
                    </div>
                    <div className="div-pane display-block-overlay">
                    <img src={whats_great} className="display-overlay" style={{"width":"100%"}}/>
                    </div>
            </div>
                   

                </div>

            </div>
        </div>
    );


}
export default WhatIsGreat;