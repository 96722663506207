import React from "react";
import './AugmentedTeam.css';
import "@fontsource/red-hat-display";
import './../min.css';
import "@fontsource/inter";
import augmented_bg from './resources/augmented_bg.svg'
import developers from './resources/developers.svg';

import designers from './resources/designers.svg';
import financeExperts from './resources/finance-experts.svg'
import projectManager from './resources/project-manager.svg'
function Talent(){
    return(
        <div className="body-talent">
        <h1>Leverage World-Class Talent</h1>
        <p>Let us streamline your organization’s adoption of DevOps practices.<br/>We’ll provide a complete turnkey Devops solution your team can run <br/>with well into the future.</p>
        <div className="row" >
        
        <div className="box box-md-3" style={{"box-shadow":"none","border-radius":"none"}}>
                <div className="WhatIsGreat-box-inline">
                    <img src={developers}/>
                    <div>
                        <h4>Developers</h4>

                        <p>Seasoned software developers, coders, and architect with expertise across hundreds of technologies.</p>
                        {/* <arrow> Explore</arrow> */}

                    </div>
                </div>

                       
                
                
            </div>        
            <div className="box box-md-3" style={{"box-shadow":"none","border-radius":"none"}}>
            <div className="WhatIsGreat-box-inline">
                    <img src={designers}/>
                    <div>
                        <h4>Designers</h4>

                        <p>We help you to source top-notch design talent</p>
                        {/* <arrow> Explore</arrow> */}

                    </div>
                </div>
            </div>          
            <div className="box box-md-3" style={{"box-shadow":"none","border-radius":"none"}}>
            <div className="WhatIsGreat-box-inline">
                    <img src={financeExperts}/>
                    <div>
                        <h4>Finance Experts</h4>

                        <p>Having financial staility is important for any company's operations we've got the right experts who helps you better manage your finances</p>
                        {/* <arrow> Explore</arrow> */}

                    </div>
                </div>
                
            </div>      
            </div>
            <div className="row" >
        
        <div className="box box-md-3" style={{"box-shadow":"none","border-radius":"none"}}>
                <div className="WhatIsGreat-box-inline">
                    <img src={projectManager}/>
                    <div>
                        <h4>Project Manager</h4>

                        <p>Project Manager that helps you to manage large scale or small scale projects</p>
                        {/* <arrow> Explore</arrow> */}

                    </div>
                </div>

                       
                
                
            </div>        
            <div className="box box-md-3" style={{"box-shadow":"none","border-radius":"none"}}>
            <div className="WhatIsGreat-box-inline">
                    <img src={developers}/>
                    <div>
                        <h4>Engineers</h4>

                        <p>Highly skilled infrastructure experts that helps you to streamline you organization’s pipeline and deployment.</p>
                        {/* <arrow> Explore</arrow> */}

                    </div>
                </div>
            </div>          
            <div className="box box-md-3" style={{"box-shadow":"none","border-radius":"none"}}>
            {/* <div className="WhatIsGreat-box-inline">
                    <img src={developers}/>
                    <div>
                        <h4>Developers</h4>

                        <p>Seasoned software engineers, coders, and architect with expertise across hundreds of technologies.</p>
                        <arrow> Explore</arrow>

                    </div>
                </div> */}
                
            </div>      
            </div>
            </div>
    );


}
export default Talent;